import Helper from '@/services/helper';

export default {
    async getStoreProvinceTagLink() {
        const result = await Helper.getAPI(Helper.apiUrl(), '/api/store/province-tag-links');
        
        return result;
    },
    async getLandingContent() {
        const result = await Helper.getAPI(Helper.apiUrl(), '/api/store/get-landing-content');
        
        return result;
    },
    async searchStore(param) {
        const result = await Helper.postAPI(Helper.apiUrl(), '/api/store/search-store', param);
        
        return result;
    },
    async getRandomStoreList() {
        const result = await Helper.getAPI(Helper.apiUrl(), '/api/store/get-random-store');
        
        return result;
    },
    async getStoreDetail(param) {
        const result = await Helper.postAPI(Helper.apiUrl(), '/api/store/get-store-detail', param);
        
        return result;
    },
    async searchProduct(param) {
        const result = await Helper.postAPI(Helper.apiUrl(), '/api/store/search-product', param);
        
        return result;
    },
    async getSingleProduct(param) {
        const result = await Helper.postAPI(Helper.apiUrl(), '/api/store/single-product', param);
        
        return result;
    },
    async checkStoreDeliverable(param) {
        const result = await Helper.postAPI(Helper.apiUrl(), '/api/store/check-deliverable', param);
        
        return result;
    }
}